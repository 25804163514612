<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
        :headers="headers"
        :items="packagePermissions"
        :loading="loading"
        hide-default-footer
        disable-pagination
        class="elevation-1"
    >
      <template v-slot:item.id="{ item }">
        <v-menu :close-on-content-click=false bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on"
            >mdi-focus-field-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item >
              <v-list-item-title>{{ item.id }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:header.add="">
        <router-link :to="{ name: 'PackagePermissionCreate' }">
          <v-avatar color="teal" size="26" rounded>
            <v-icon dark>mdi-plus</v-icon>
          </v-avatar>
        </router-link>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
              :to="{
              name: 'PackagePermissionEdit',
              params: { packagePermissionId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import MembershipApiService from "@/core/services/api.service.membership";
import {mapGetters} from "vuex";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

export default {
  name: "package-permissions",
  computed: {
    ...mapGetters(["currentUser"]),
    headers() {
      return [
        {text: "Id", align: "start", value: "id", sortable: false},
        {text: this.$i18n.t('common.name'), value: "externalId", sortable: false},
        {text: this.$i18n.t('common.ordinalNumber'), value: "ordinalNumber", sortable: false},
        {text: "", value: "edit", width: "5%", sortable: false},
        {text: "", value: "add", width: "5%", sortable: false}
      ]
    }
  },
  data() {
    return {
      packagePermissions: [],
      loading: false,
      membershipApiInitiated: false,
      loader: null,
      errors: []
    };
  },
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    this.$log.debug("await init memb service - start");
    await MembershipApiService.init();
    this.$log.debug("await init memb service - end");
    this.membershipApiInitiated = true;
    this.retrievePackagePermissions();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Package Permissions"}]);
  },
  components: {
    ConfirmDialog
  },
  methods: {

    async delRecord(item) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to delete permission with id: " + item.id + " ?"
          )
      ) {
        this.deletePackage(item);
      }
    },
    deletePackage(item) {
      this.loading = true;
      if (item.id != null) {
        MembershipApiService.delete(`/CatalogItems/RemoveShopPermissionDefinitions?permissionDefinition=${item.id}`)
            .then(response => {
              this.$log.debug("Deleted package permission: ", item.id);
              this.$log.debug("Response: ", response);
            })
            .catch(error => {
              this.$log.error("Error: ", error);
              this.errored = true;
            })
            .finally(() => (this.retrievePackagePermissions(), this.loading = false));
      }
    },
    parseDate(timestamp) {
      return moment
          .utc(timestamp)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
    },
    retrievePackagePermissions() {
      let params = {};
      return new Promise(resolve => {
        this.loading = true;
        MembershipApiService.query("/CatalogItems/GetPermissionDefinitions",
            params
        ).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("package permissions response: ", response.data);
          this.packagePermissions = response.data;
          this.loading = false;
          resolve();
        });
      });
    }
  }
};


</script>
<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

tbody tr {
  width: 100%;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: aliceblue;
  border: 1px solid brown;
}

.search {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.search-item {
  justify-content: center;
  display: flex;
}

</style>
